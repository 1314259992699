import ajax from "@/utils/request.js";
export const user = {
  login: (data) => ajax.post("/login", data),
  dlogin: (data) => ajax.post("/sms/login", data),
  getValidateCode: (data) =>
    ajax.get("/validateCode/image", {
      responseType: "arraybuffer",
      params: data,
    }),
  register: (data) => ajax.post("/register/save", data),
  // 发送登陆短信验证
  sendLoginCode: (data) =>
    ajax.get("/sms/sendLoginCode", {
      params: data,
    }),
  // 发送注册短信验证
  sendRegisterCode: (data) =>
    ajax.get("/sms/sendRegisterCode", {
      params: data,
    }),
  //检验注册短信验证码
  checkRegister: (data) => ajax.post("/sms/checkRegisterSmsCode", data),

  // 发送找回密码验证码
  sendPasswordCode: (data) =>
    ajax.get("/sms/sendFindPasswordCode", {
      params: data,
    }),

  //检验找回密码短信验证码
  checkFindPasswordSmsCode: (data) =>
    ajax.post("/sms/checkFindPasswordSmsCode", data),
  //检验找回密码校验码
  checkFindPasswordCheckCode: (data) =>
    ajax.post("/sms/checkFindPasswordCheckCode", data),
  // 找回保存密码
  findSavePassword: (data) => ajax.post("/findPassword/savePassword", data),
};
